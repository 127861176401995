// import ReactPaginate from "react-paginate";
// import React, { useEffect, useState } from "react";
// import { TwitterTweetEmbed } from "react-twitter-embed";
// import { Container, Row, Col, Visible } from "react-grid-system";

// import { CircularProgress } from "@material-ui/core";


// function TweetContainer(props) {
//   const [currentPage, setCurrentPage] = useState(0);
//   const [tweetsData, settweetsData] = useState([]);
//   const [loading, setLoading] = useState(true); // Add a loading state and set it to true initially

//   useEffect(() => {
//     settweetsData(props.data);
//     setLoading(false); // Set loading to false when tweets are received
//   }, [props.data]);

//   const handlePageClick = ({ selected: selectedPage }) => {
//     setCurrentPage(selectedPage);
//   };
  
//   const itemsPerPage = 9;
//   const offset = currentPage * itemsPerPage;
//   var tweetCol1 = [];
//   var tweetCol2 = [];
//   var tweetCol3 = [];

//   tweetsData.slice(offset, offset + itemsPerPage).map((tweet, index) => {
//     if (index % 3 === 0) {
//       tweetCol1.push(tweet);
//     } else if (index % 3 === 1) {
//       tweetCol2.push(tweet);
//     } else {
//       tweetCol3.push(tweet);
//     }
//   });



//   const currentPageData = (
//     <Container>
//       <Row style={{ justifyContent: "spaceEvenly", flexWrap: "wrap" }}>
//         <Col md={12} lg={4} xl={4}>
//           {tweetCol1 &&
//             tweetCol1.map((tweet) => (           
//               <TwitterTweetEmbed key={tweet} tweetId={tweet} />
//             ))}
//         </Col>
//         <Col md={12} lg={4} xl={4}>
//           {tweetCol2 &&
//             tweetCol2.map((tweet) => (
//               <TwitterTweetEmbed key={tweet} tweetId={tweet} />
//             ))}
//         </Col>
//         <Col md={12} lg={4} xl={4}>
//           {tweetCol3 &&
//             tweetCol3.map((tweet) => (
//               <TwitterTweetEmbed key={tweet} tweetId={tweet} />
//             ))}
//         </Col>
//       </Row>
//     </Container>
//   );

//   const pageCount = Math.ceil(tweetsData.length / itemsPerPage);

//   return (
//     <div className="tweetContainer">
//      {loading ? ( // Conditionally render loading indicator or tweets
//         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
//           <CircularProgress />
//         </div>
//       ) : (
//         currentPageData
//       )}
//       <div className="tweets-footer" style={{ paddingTop: 30 }}>
//         <div className="row" style={{ margin: 0, justifyContent: "center" }}>
//           <Visible lg xl xxl>
//             <ReactPaginate
//               className="pagination justify-content-center"
//               pageClassName="page-item"
//               previousClassNamee="page-item"
//               nextClassNamee="page-item"
//               breakClassName="page-item"
//               previousLinkClassName="page-link"
//               nextLinkClassName="page-link"
//               breakLinkClassName="page-link"
//               pageLinkClassName="page-link"
//               activeClassName="active"
//               breakLabel="..."
//               nextLabel=" >"  
//               onPageChange={handlePageClick}
//               pageRangeDisplayed={3}
//               marginPagesDisplayed={3}
//               pageCount={pageCount}
//               previousLabel="< "
//               renderOnZeroPageCount={null}
//               // eslint-disable-next-line no-unused-vars
//               hrefBuilder={(page, pageCount, selected) =>
//                 page >= 1 && page <= pageCount ? `/page/${page}` : "#"
//               }
//               hrefAllControls
//             />
//           </Visible>
//           <Visible xs sm md>
//             <ReactPaginate
//               className="pagination justify-content-center"
//               pageClassName="page-item"
//               previousClassNamee="page-item"
//               nextClassNamee="page-item"
//               breakClassName="page-item"
//               previousLinkClassName="page-link"
//               nextLinkClassName="page-link"
//               breakLinkClassName="page-link"
//               pageLinkClassName="page-link"
//               activeClassName="active"
//               breakLabel="..."
//               nextLabel=" >"
//               onPageChange={handlePageClick}
//               pageRangeDisplayed={2}
//               marginPagesDisplayed={currentPage > 2 ? 1 : 2}
//               pageCount={pageCount}
//               previousLabel="< "
//               renderOnZeroPageCount={null}
//             />
//           </Visible>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default TweetContainer;
// TweetContainer.js

// import ReactPaginate from "react-paginate";
// import React, { useEffect, useState } from "react";
// import { Container, Row, Col, Visible } from "react-grid-system";
// import { CircularProgress } from "@material-ui/core";
// import TweetItem from "./TweetItem"; // Import TweetItem

// function TweetContainer(props) {
//   const [currentPage, setCurrentPage] = useState(0);
//   const [tweetsData, settweetsData] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     settweetsData(props.data);
//     setLoading(false);
//   }, [props.data]);

//   const handlePageClick = ({ selected: selectedPage }) => {
//     setCurrentPage(selectedPage);
//   };

//   const itemsPerPage = 9;
//   const offset = currentPage * itemsPerPage;
//   const currentTweets = tweetsData.slice(offset, offset + itemsPerPage);

//   // Split tweets into three columns
//   const tweetCol1 = [];
//   const tweetCol2 = [];
//   const tweetCol3 = [];

//   currentTweets.forEach((tweetId, index) => {
//     if (index % 3 === 0) {
//       tweetCol1.push(tweetId);
//     } else if (index % 3 === 1) {
//       tweetCol2.push(tweetId);
//     } else {
//       tweetCol3.push(tweetId);
//     }
//   });

//   const currentPageData = (
//     <Container>
//       <Row style={{ justifyContent: "spaceEvenly", flexWrap: "wrap" }}>
//         <Col md={12} lg={4} xl={4}>
//           {tweetCol1.map((tweetId) => (
//             <TweetItem key={tweetId} tweetId={tweetId} />
//           ))}
//         </Col>
//         <Col md={12} lg={4} xl={4}>
//           {tweetCol2.map((tweetId) => (
//             <TweetItem key={tweetId} tweetId={tweetId} />
//           ))}
//         </Col>
//         <Col md={12} lg={4} xl={4}>
//           {tweetCol3.map((tweetId) => (
//             <TweetItem key={tweetId} tweetId={tweetId} />
//           ))}
//         </Col>
//       </Row>
//     </Container>
//   );

//   const pageCount = Math.ceil(tweetsData.length / itemsPerPage);

//   return (
//     <div className="tweetContainer">
//       {loading ? (
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             height: "100px",
//           }}
//         >
//           <CircularProgress />
//         </div>
//       ) : (
//         currentPageData
//       )}
//       <div className="tweets-footer" style={{ paddingTop: 30 }}>
//         <div className="row" style={{ margin: 0, justifyContent: "center" }}>
//           <Visible lg xl xxl>
//             <ReactPaginate
//               className="pagination justify-content-center"
//               pageClassName="page-item"
//               previousClassName="page-item"
//               nextClassName="page-item"
//               breakClassName="page-item"
//               previousLinkClassName="page-link"
//               nextLinkClassName="page-link"
//               breakLinkClassName="page-link"
//               pageLinkClassName="page-link"
//               activeClassName="active"
//               breakLabel="..."
//               nextLabel=" >"
//               onPageChange={handlePageClick}
//               pageRangeDisplayed={3}
//               marginPagesDisplayed={3}
//               pageCount={pageCount}
//               previousLabel="< "
//               renderOnZeroPageCount={null}
//               hrefBuilder={(page, pageCount, selected) =>
//                 page >= 1 && page <= pageCount ? `/page/${page}` : "#"
//               }
//               hrefAllControls
//             />
//           </Visible>
//           <Visible xs sm md>
//             <ReactPaginate
//               className="pagination justify-content-center"
//               pageClassName="page-item"
//               previousClassName="page-item"
//               nextClassName="page-item"
//               breakClassName="page-item"
//               previousLinkClassName="page-link"
//               nextLinkClassName="page-link"
//               breakLinkClassName="page-link"
//               pageLinkClassName="page-link"
//               activeClassName="active"
//               breakLabel="..."
//               nextLabel=" >"
//               onPageChange={handlePageClick}
//               pageRangeDisplayed={2}
//               marginPagesDisplayed={currentPage > 2 ? 1 : 2}
//               pageCount={pageCount}
//               previousLabel="< "
//               renderOnZeroPageCount={null}
//             />
//           </Visible>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default TweetContainer;


// TweetContainer.js

// TweetContainer.js
// TweetContainer.js// TweetContainer.js

import ReactPaginate from "react-paginate";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Visible } from "react-grid-system";
import { CircularProgress } from "@material-ui/core";
import TweetItem from "./TweetItem"; // 导入 TweetItem

function TweetContainer(props) {
  const [currentPage, setCurrentPage] = useState(0);
  const [tweetsData, setTweetsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTweetsData(props.data);
    setLoading(false);
  }, [props.data]);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const itemsPerPage = 9;
  const offset = currentPage * itemsPerPage;

  // 初始化列
  const tweetCol1 = [];
  const tweetCol2 = [];
  const tweetCol3 = [];

  // 为当前页的推文分配列
  tweetsData.slice(offset, offset + itemsPerPage).forEach((tweetId, index) => {
    if (index % 3 === 0) {
      tweetCol1.push(tweetId);
    } else if (index % 3 === 1) {
      tweetCol2.push(tweetId);
    } else {
      tweetCol3.push(tweetId);
    }
  });

  const currentPageData = (
    <Container style={{ maxWidth: "2000px", margin: "0 auto" }}>
      <Row style={{ justifyContent: "center", flexWrap: "wrap" }}>
        <Col
          xs={12}
          sm={6}
          md={4}
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            boxSizing: "border-box",
          }}
        >
          {tweetCol1.map((tweetId) => (
            <div key={tweetId} style={{ marginBottom: "20px" }}>
              <TweetItem tweetId={tweetId} />
            </div>
          ))}
        </Col>
        <Col
          xs={12}
          sm={6}
          md={4}
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            boxSizing: "border-box",
          }}
        >
          {tweetCol2.map((tweetId) => (
            <div key={tweetId} style={{ marginBottom: "20px" }}>
              <TweetItem tweetId={tweetId} />
            </div>
          ))}
        </Col>
        <Col
          xs={12}
          sm={6}
          md={4}
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            boxSizing: "border-box",
          }}
        >
          {tweetCol3.map((tweetId) => (
            <div key={tweetId} style={{ marginBottom: "20px" }}>
              <TweetItem tweetId={tweetId} />
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  );

  const pageCount = Math.ceil(tweetsData.length / itemsPerPage);

  return (
    <div className="tweetContainer">
      {loading ? (
        // 条件渲染加载指示器或推文
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        currentPageData
      )}
      <div className="tweets-footer" style={{ paddingTop: 30 }}>
        <div className="row" style={{ margin: 0, justifyContent: "center" }}>
          <Visible lg xl xxl>
            <ReactPaginate
              className="pagination justify-content-center"
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              breakClassName="page-item"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              breakLinkClassName="page-link"
              pageLinkClassName="page-link"
              activeClassName="active"
              breakLabel="..."
              nextLabel=" >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={3}
              pageCount={pageCount}
              previousLabel="< "
              renderOnZeroPageCount={null}
              hrefBuilder={(page, pageCount, selected) =>
                page >= 1 && page <= pageCount ? `/page/${page}` : "#"
              }
              hrefAllControls
            />
          </Visible>
          <Visible xs sm md>
            <ReactPaginate
              className="pagination justify-content-center"
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              breakClassName="page-item"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              breakLinkClassName="page-link"
              pageLinkClassName="page-link"
              activeClassName="active"
              breakLabel="..."
              nextLabel=" >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              marginPagesDisplayed={currentPage > 2 ? 1 : 2}
              pageCount={pageCount}
              previousLabel="< "
              renderOnZeroPageCount={null}
            />
          </Visible>
        </div>
      </div>
    </div>
  );
}

export default TweetContainer;