import React, { useRef, useEffect,useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import ArticlePreviewer from "./ArticlePreviewer";
// import ArticleFilter from "./ArticleFilter";
import TableauWordCloud from "./TableauWordCloud";
const { tableau } = window;

function TableauSentimentReport() {
  const ref = useRef(null);
  
  const url =
    // "https://public.tableau.com/shared/JMTX3RX72?:display_count=n&:origin=viz_share_link";
    // "https://public.tableau.com/views/FloodSentimentAnalysis/1_1?:language=en-US&publish=yes&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link";
    "https://public.tableau.com/views/FloodSentimentAnalysis/1_1?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link";
  const initViz = () => {
    new tableau.Viz(ref.current, url, {
      width: "100%",
      height: "70vh",
    });
  };

  useEffect(initViz, []);
  return <div ref={ref} />;
}

// This file is to distinguish from the hightlight.js
function HighLights() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    fetch('/ListOfArticles.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setArticles(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  return (
    <div
      className="container"
      style={{ fontFamily: "Noto Sans", paddingTop: 20 }}
    >
      <Container style={{ fontFamily: "Noto Sans", paddingTop: 30 }}>
        <Row style={{ paddingBottom: 30 }}>
          <Col md={12} style={{ textAlign: "left" }}>
            <h2>Highlights</h2>
            {/* <Row>
              <p>This is for Tableau Timeline chart in the future.</p>
            </Row> */}
            <Row
              style={{
                margin: 0,
                paddingTop: 20,
                justifyContent: "center",
                display: "block",
              }}
            >
              <TableauSentimentReport />
            </Row>
            <br /><br />
            <h5>&nbsp; Wordcloud</h5>
            <TableauWordCloud />
            <br></br>
            <br></br>
            <Row>
              <h2>Relevant News Articles</h2>
            </Row>
            <Row
              style={{
                margin: 0,
                paddingTop: 20,
                justifyContent: "center",
                display: "block",
              }}
            >
              {/* <img
                style={{ height: "500px", paddingBottom: "10px" }}
                alt=""
                src="https://res.cloudinary.com/dpgdagcd0/image/upload/v1662329095/top150_words_rvzdwa.png"
                className="img-wordcloud"
              ></img> */}
            </Row>
          </Col>
        </Row>
        <ArticlePreviewer data = {articles} limit = {500}/>
      </Container>
    </div>
  );
}

export default HighLights;
