import React, { Component } from "react";
// import map from "./20220906_usdm.png";
import "bootstrap/dist/css/bootstrap.css";
// import { ref, getDownloadURL } from "firebase/storage";
// import { storage } from "../firebase.config";

class Home extends Component {
    // constructor() {
    //     super();
    //     this.state = {
    //         usdm: "",
    //     };

    //     this.getImage("usdm");
    // }
    constructor() {
        super();
        this.state = {
            flood_img: "https://waterwatch.usgs.gov/images/flood/us/floodb.gif",  // direct link to the image
            notion: "https://waterwatch.usgs.gov/images/map_legends/flood_dot_n_shd_wnd.gif"
        };
    }

    // getImage(image) {
    //     let { state } = this;
    //     const imagesRef = ref(storage, "Web_media/usdm_home.png");

    //     getDownloadURL(imagesRef).then((url) => {
    //         state[image] = url;
    //         console.log("state[image]", url);
    //         this.setState(state);
    //     });
    // }

    routeChangeWeb = () => {
        this.props.setValue(1);
        this.props.history.push("/articles");
    };

    routeChangeSocialmedia = () => {
        this.props.setValue(2);
        this.props.history.push("/socialmedia");
    };

    // routeChangeHighLight = () => {
    //     this.props.setValue(3);
    //     this.props.history.push("/insights");
    // };

    routeChangeNewHighLight = () => {
        this.props.setValue(4);
        this.props.history.push("/highlight");
    };

    // routeChangeDisplay = () => {
    //     this.props.setValue(5);
    //     this.props.history.push("/display");
    // };

    routeChangeInput = () => {
        this.props.setValue(6);
        this.props.history.push("/userinput");
    };

    render() {
        // const { titleForSearch, tutorials, currentTutorial, currentIndex } = this.state;
        return (
            <div className="container fluid" style={{ fontFamily: "Noto Sans", textAlign: 'center'}}>
                <p style={{ paddingTop: 30 }}></p>
                <h4 align="left">
                    Welcome to Dialogue and Information Platform for a Flood Event (DIP-Flood)!
                </h4>
                <p align="left">
                    As shown by <a href="https://waterwatch.usgs.gov/?id=ww_flood" target="_blank" rel="noopener noreferrer">USGS WaterWatch</a>,
                    the flooding situation in the U.S. has been severe in 2024, particularly affecting states in the Midwest and Southern regions.
                    Notable areas impacted include Florida, South Dakota, Minnesota, Iowa, Texas, and other parts of the States.
                </p>
            
                {/* <img
                    id="usdm"
                    src={this.state.usdm}
                    style={{maxHeight: '400px', paddingBottom: 15}}
                    className='img-fluid shadow-4'
                /> */}

                <img
                    id="flood"
                    src={this.state.flood_img}  // use link to display the image
                    style={{maxHeight: '400px', paddingBottom: 15}}
                    className='img-fluid shadow-4'
                    alt="flood"
                />

                <img
                    id="flood"
                    src={this.state.notion}  // use link to display the image
                    style={{maxHeight: '400px', paddingBottom: 15}}
                    className='img-fluid shadow-4'
                    alt="notion"
                />

                <p align="left">
                    {/* This mega-drought is probably the worst in the recent history of the
                    western region, and it has already severely affected agriculture,
                    water supply, hydropower, environment and ecosystems, and local and
                    regional economy in a number of states. */}
                    The impacts of these floods have been profound, affecting agriculture, infrastructure, and local economies. 
                    Communities face long-term recovery challenges, including the need for repair of homes and businesses, replacement of personal property, 
                    and restoration of public infrastructure.
                </p>
                <p align="left">In DIP-Flood, you can</p>
                <ul align="left">
                    <li>
                        Find 145 {" "}
                        <a
                            href=""
                            onClick={() => {
                                this.routeChangeWeb();
                            }}
                        >
                            articles 
                        </a>{" "}
                         about the hurricane caused flood status and impacts published by national, regional
                        and local news media updated over time.
                    </li>
                    <li>
                        Share your observation, damage estimate, and opinion{" "}
                        <a
                            href=""
                            onClick={() => {
                                this.routeChangeInput();
                            }}
                        >
                            here
                        </a>.
                    </li>
                    <li>
                        Review major topics and messages from{" "}
                        <a
                            href=""
                            onClick={() => {
                                this.routeChangeSocialmedia();
                            }}
                        >
                            social media
                        </a>{" "}
                        and some{" "}
                        <a
                            href=""
                            onClick={() => {
                                this.routeChangeNewHighLight();
                            }}
                        >
                            highlights
                        </a>
                        .
                    </li>
                </ul>
                <h4 align="left">Contact Us</h4>
                <p align="left">
                    Please contact the deep flood department team at University of
                    Illinois Urbana-Champaign.
                </p>
                <ul align="left">
                    {/* <li>
                        Ruichen Yao:{" "}
                        <a href="mailto: ryao8@illinois.edu">ryao8@illinois.edu</a>
                    </li> */}
                    <li>
                        Dr. Dong Wang:{" "}
                        <a href="mailto: dwang24@illinois.edu">dwang24@illinois.edu</a>
                    </li>
                    <li>
                        Dr. Ximing Cai:{" "}
                        <a href="mailto: xmcai@illinois.edu">xmcai@illinois.edu</a>
                    </li>
                </ul>
            </div>
        );
    }
}
export default Home;
