import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";


const customTheme = createTheme({
  typography: {
    fontFamily: ["Encode Sans SC"].join(","),
  },
  palette: {
    background: {
      paper: '#e4f0e2'
    }
  },
});


function DrawerComponent() {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div>
      <ThemeProvider theme={customTheme}>
        <Drawer open={openDrawer} variant="temporary" onClose={() => setOpenDrawer(false)}>
          <List>
            <h2 style={{ paddingTop: 10, paddingLeft: 15, fontFamily: "Encode Sans SC"}}>Menu</h2>
            <br />
            <ListItem onClick={() => setOpenDrawer(false)}>
              <ListItemText>
                <Link to="/home">Home</Link>
              </ListItemText>
            </ListItem>
            <ListItem onClick={() => setOpenDrawer(false)}>
              <ListItemText>
                <Link to="/articles">Articles</Link>
              </ListItemText>
            </ListItem>
            <ListItem onClick={() => setOpenDrawer(false)}>
              <ListItemText>
                <Link to="/socialmedia">SocialMedia</Link>
              </ListItemText>
            </ListItem>
            <ListItem onClick={() => setOpenDrawer(false)}>
              <ListItemText>
                <Link to="/highlight">HighLights</Link>
              </ListItemText>
            </ListItem>
            {/* <ListItem onClick={() => setOpenDrawer(false)}>
              <ListItemText>
                <Link to="/insight">Insights</Link>
              </ListItemText>
            </ListItem> */}
            <ListItem onClick={() => setOpenDrawer(false)}>
              <ListItemText>
                <Link to="/userinput">Share</Link>
              </ListItemText>
            </ListItem>
          </List>
        </Drawer>
      </ThemeProvider>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon style={{ color: "white" }} />
        <img src="/uiuc.png" alt="Logo" height="80" />
      </IconButton>
    </div>
  );
}
export default DrawerComponent;
