// TweetItem.js

import React, { useState } from 'react';
import { Tweet } from 'react-twitter-widgets';

// function TweetItem({ tweetId }) {
//   const [tweetLoadError, setTweetLoadError] = useState(false);

//   const handleLoadError = () => {
//     setTweetLoadError(true);
//   };

//   if (tweetLoadError) {
//     return null; // Or <div>This tweet is unavailable.</div>;
//   }

//   return (
//     <Tweet
//       tweetId={tweetId}
//       options={{ conversation: 'none' }}
//       onError={handleLoadError}
//     />
//   );
// }

// export default TweetItem;


function TweetItem({ tweetId }) {
  const [tweetStatus, setTweetStatus] = useState('loading'); // 'loading', 'loaded', 'error'

  const handleLoadSuccess = () => {
    console.log(`Tweet ${tweetId} loaded successfully`);
    setTweetStatus('loaded');
  };

  const handleLoadError = () => {
    console.log(`Failed to load tweet ${tweetId}`);
    setTweetStatus('error');
  };

  return (
    <>
      {tweetStatus === 'error' ? (
        <div>This tweet is unavailable.</div>
      ) : (
        <div
          style={{
            opacity: tweetStatus === 'loaded' ? 1 : 0,
            height: tweetStatus === 'loaded' ? 'auto' : 0,
            overflow: 'hidden',
          }}
        >
          <Tweet
            tweetId={tweetId}
            options={{ width: '100%', conversation: 'none' }}
            // options={{ conversation: 'none' }}
            onLoad={handleLoadSuccess}
            onError={handleLoadError}
          />
        </div>
      )}
    </>
  );
}

export default TweetItem;