import React from "react";
import { ref, onValue } from "firebase/database";
import ReactPaginate from "react-paginate";
import { Visible } from "react-grid-system";
import { db } from "../firebase.config";
import "bootstrap/dist/css/bootstrap.css";
import { Modal } from "react-bootstrap";
import './Display.css'


class Display extends React.Component {
  state = {
    data: [],
    showPerPage: [],
    page: 0
  };


  // set up all userinput tweets from firebase
  // componentDidMount() {
  //   onValue(ref(db, "users"), (snapshot) => {
  //     const data = snapshot.val();
  //     const arr = Object.values(data);
  //     arr.reverse();
  //     this.setState((prevState) => ({
  //       ...prevState,
  //       data: arr
  //     }));
  //   });
  // }

  componentDidMount() {
    onValue(ref(db, "users"), (snapshot) => {
      const data = snapshot.val();
      
      // 检查 data 是否为 null 或 undefined
      if (data) {
        const arr = Object.values(data);
        arr.reverse();
        this.setState((prevState) => ({
          ...prevState,
          data: arr
        }));
      } else {
        // 如果没有数据，则设置一个空数组
        this.setState((prevState) => ({
          ...prevState,
          data: []
        }));
      }
    });
  }


  render() {
    // set up the current page
    const handlePageClick = ({ selected: selectedPage }) => {
      this.setState({page: selectedPage});
    };
    // limit the maximum tweets per page to 6
    const itemsPerPage = 6;

    // find the corresponding tweets to show on the current page
    const offset = this.state.page * itemsPerPage;
    this.state.showPerPage = this.state.data.slice(offset, offset + itemsPerPage); 

    // count how many pages we have in total
    const numberofPages = Math.ceil(this.state.data.length / itemsPerPage);
    return (
      <div className="container justify-content-center">
        <div className="row">
          {this.state.showPerPage.map((item, index) => {
            var content = item.content;
            
            const matches = content.match(
              /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
            );
            if (matches) {
              for (const element of matches) {
                content = content.replaceAll(element, `!${element}`); // "!"+element
              }
            }
            content = content.split("!");
            // console.log( content );
            //["123", "http://", "312", "http"] --> ["123", <a href='#'>element</a>, "312", <a href='#'>element</a>]
            for (var i = 0; i < content.length; i++) {
              if (content[i].startsWith("http")) {
                content[i] = <a href={content[i]}>{content[i]}</a>;
              }
            }
            return (     
              <Modal.Dialog key={index} className="modal-33">
                <Modal.Header>
                  <Modal.Title>
                    {item.username} from {item.zip ? item.zip : item.state}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                  <p>{content}</p>
                </Modal.Body>
              </Modal.Dialog>
              // <Box component="fieldset">
              //   <legend> {item.username} from {item.zip ? item.zip : item.state} </legend>
              //   {content}
              // </Box>
            );
          })}
        </div>

        <div className="tweets-footer" style={{ paddingTop: 30 }}>
          <div className="row" style={{ margin: 0, justifyContent: "center" }}>
            <Visible lg xl xxl>
              <ReactPaginate
                className="pagination justify-content-center"
                pageClassName="page-item"
                previousClassNamee="page-item"
                nextClassNamee="page-item"
                breakClassName="page-item"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                breakLinkClassName="page-link"
                pageLinkClassName="page-link"
                activeClassName="active"
                breakLabel="..."
                nextLabel=" >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={3}
                pageCount={numberofPages}
                previousLabel="< "
                renderOnZeroPageCount={null}
                hrefBuilder={(page, numberofPages, selected) =>
                  page >= 1 && page <= numberofPages ? `/page/${page}` : "#"
                }
                hrefAllControls
              />
            </Visible>
            <Visible xs sm md>
              <ReactPaginate
                className="pagination justify-content-center"
                pageClassName="page-item"
                previousClassNamee="page-item"
                nextClassNamee="page-item"
                breakClassName="page-item"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                breakLinkClassName="page-link"
                pageLinkClassName="page-link"
                activeClassName="active"
                breakLabel="..."
                nextLabel=" >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={this.state.page > 2 ? 1 : 2}
                pageCount={numberofPages}
                previousLabel="< "
                renderOnZeroPageCount={null}
              />
            </Visible>
          </div>
        </div>
      </div>
    );
  }
}
export default Display;
