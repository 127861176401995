import React, { Component } from "react";
import { writeUserData } from "../firebase.config";
import Display from './Display'
// Importing toastify module
import { toast } from "react-toastify";
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import "./UserInput.css"; 
// import { Button, TextField, IconButton, Drawer } from "@material-ui/core";
import { Button, TextField, IconButton} from "@material-ui/core";
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import CloseIcon from '@material-ui/icons/Close';

// import homeImg from '../assets/homeImg.png'
import {DeepChat} from 'deep-chat-react';
// require('dotenv').config();

// toast-configuration method,
// it is a compulsory method.
toast.configure();

let zipCodes = require("../zip_code_database.json");

class UserInput extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeZip = this.handleChangeZip.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.pushDataToFirebase = this.pushDataToFirebase.bind(this);

    this.state = {
                  username: "",
                  content: "",
                  zip: "",
                  isChatOpen: false,

                };
   
  }
  //
  


  handleChange(e) {
    const userInput = e.target.value;
    this.setState({
      content: userInput
    });
  }


  handleChangeZip(e) {
    const userInput = e.target.value;
    this.setState({
      zip: userInput
    });
  }


  handleChangeName(e) {
    const userInput = e.target.value;
    this.setState({
      username: userInput
    });
  }


  componentDidUpdate() {
    // console.log(this.state);
    localStorage.setItem("formData", JSON.stringify(this.state));

  }
  toggleChat = () => {
    this.setState(prevState => ({ isChatOpen: !prevState.isChatOpen }));
  }

  async pushDataToFirebase() {
    const arr = zipCodes.filter(
      (zipCode) => zipCode.zip === parseInt(this.state.zip)
    );
    // console.log(arr); // Print the area information in console

    if (this.state.content.length !== 0 && arr.length !== 0) {
      // Check the input zip code, if it is invalid then push data to fb
      try {
        if (this.state.username !== "") {
          // console.log(this.state.username);
          await writeUserData(this.state.content, this.state.zip, this.state.username);
        }
        else
          await writeUserData(this.state.content, this.state.zip);
        console.log("writing succeed");
        this.successMessage();
        // this.routeChange();
      } catch (error) {
          toast.error("Error occurs when adding data!");
        console.error("Error adding data to fb", error);
      }
    } else {
      if (this.state.content.length === 0)
        this.errorMessage("You need to add descriptions!");
      if (arr.length === 0)
        this.errorMessage("Please enter valid Zip Code!");
      if (this.zip === "")
        this.errorMessage("Zip Code cannot be empty!");
    }
  }

  // routeChange = () => {
  //   this.props.setValue(3);
  //   this.props.history.push("/display");
  // };


  successMessage = () => {
    toast.success("Thank you for your feedback!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };


  errorMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  render() {
    // const { titleForSearch, tutorials, currentTutorial, currentIndex } = this.state;
    const initialMessages = [
      // { role: 'user', text: 'Hey, how are you today?' },
      // { role: 'ai', text: 'Welcome!?' },
      {
        html: `
          <div class="deep-chat-temporary-message">
            <button class="deep-chat-button deep-chat-suggestion-button" style="margin-top: 5px">I want to be a contributor</button>
            <button class="deep-chat-button deep-chat-suggestion-button" style="margin-top: 6px">I want to submit a feedback</button>
            <button class="deep-chat-button deep-chat-suggestion-button" style="margin-top: 6px">I found a bug on the website</button>
          </div>`,
          role: 'ai',

      },
    ];
    const customStyles = {
      borderRadius: '10px',
      borderColor: '#dcdcdc',
      backgroundColor: '#f3f6fc', position: 'fixed', bottom: '1%', right: '1%', zIndex: '1' ,height: '400px'
    };
  
    const textInputConfig = {
      styles: {
        container: {
          borderRadius: '20px',
          border: '1px solid #969696',
          boxShadow: 'unset',
          width: '78%',
          marginLeft: '-15px',
        },
        text: { padding: '10px', paddingLeft: '15px', paddingRight: '34px' },
      },
      placeholder: { text: 'Enter a prompt here', style: { color: '#bcbcbc' } },
    };
  
    const messageStylesConfig = {
      default: {
        shared: {
          bubble: {
            maxWidth: '100%',
            backgroundColor: 'unset',
            marginTop: '10px',
            marginBottom: '10px',
          },
        },
        user: { bubble: { marginLeft: '0px', color: 'black' } },
        ai: {
          innerContainer: { borderRadius: '15px', backgroundColor: 'white' },
        },
      },
    };
  
    const avatarsConfig = {
      default: {
        styles: {
          position: 'left',
          container: { marginLeft: '12px', marginRight: '5px' },
        },
      },
      user: {
        src: '      https://icons.veryicon.com/png/o/miscellaneous/social-applet-1/user-201.png',
        styles: { position: 'left', avatar: { paddingTop: '6px' } },
      },
      ai: {
        src: 'https://icons.veryicon.com/png/o/cartoon/bicolor-icon/robot-9.png',
        styles: { position: 'left', avatar: { paddingTop: '6px' } },
      },
    };
  
    
  
    const submitButtonStylesConfig = {
      position: 'outside-right',
      submit: {
        container: {
          default: {
            bottom: '0.9em',
            borderRadius: '25px',
            padding: '6px 5px 4px',
            backgroundColor: '#f3f6fc',
          },
          hover: { backgroundColor: '#b0deff4f' },
          click: { backgroundColor: '#b0deffb5' },
        },
        svg: {
          "content": "<?xml version=\"1.0\" encoding=\"utf-8\"?> <svg viewBox=\"0 0 24 24\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m21.426 11.095-17-8A.999.999 0 0 0 3.03 4.242L4.969 12 3.03 19.758a.998.998 0 0 0 1.396 1.147l17-8a1 1 0 0 0 0-1.81zM5.481 18.197l.839-3.357L12 12 6.32 9.16l-.839-3.357L18.651 12l-13.17 6.197z\"/></svg>",
          styles: {
            default: {
              width: '1.5em',
              filter:
                'brightness(0) saturate(100%) invert(10%) sepia(86%) saturate(6044%) hue-rotate(205deg) brightness(100%) contrast(100%)',
            },
          },
        },
      },
    };
    const { isChatOpen } = this.state;
    // const chatClass = `deep-chat-container ${isChatOpen ? 'open' : 'closed'}`;
    const chatStyle = {
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      zIndex: 1000, // Adjust as needed to ensure it's above other elements
      // maxWidth: '300px', // Set maximum width
      // maxHeight: '400px', // Set maximum height
      
      overflow: 'auto', // Add scrollbars if content overflows
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      display: isChatOpen ? 'block' : 'none', // Only display when isChatOpen is true
    };
//connection and key
    const directConnection = {
      openAI: {
        key: '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',
        chat: {
          // system_prompt: `The impact of drought on communities, economies, and the environment is profound and multifaceted, leading to a range of issues from economic losses and mental health struggles to environmental degradation and social isolation. We seek to understand these impacts more deeply and explore effective mitigation strategies. Please share your personal experiences, observations, or local stories related to the effects of drought. Discuss any economic, health, or social challenges you've faced due to drought conditions, and suggest any ideas you have for reducing these negative impacts. Your insights are valuable in painting a comprehensive picture of drought effects and exploring solutions together.`,
          system_prompt: `The impact of flooding on communities, economies, and the environment is profound and multifaceted, leading to a range of issues from economic losses and mental health struggles to environmental damage and social disruption. We seek to understand these impacts more deeply and explore effective mitigation strategies. Please share your personal experiences, observations, or local stories related to the effects of flooding. Discuss any economic, health, or social challenges you've faced due to flood conditions, and suggest any ideas you have for reducing these negative impacts. Your insights are valuable in painting a comprehensive picture of flood effects and exploring solutions together.`,
        },
      },
    };


    //user input 
    
    return (
      <div
        className="container"
        style={{ fontFamily: "Noto Sans", paddingBottom: 30 }}
      >
        <p style={{ paddingTop: 30 }}></p>
        <h4 align="left">Your Sharings Matter!</h4>
        {/* <p align="left">
          The drought has caused serious erosion of income for farms and small
          businesses, anxiety or depression about economic losses, conflicts and
          violence with reduced water supply, increased workloads, fewer
          recreational activities, threats due to forest loss and wildfires,
          health incidents (e.g., of heat stroke), as well as isolation from
          community networks.{" "}
          <b>Have any of these effects occurred to you or people you know?</b>
        </p>
        <p align="left">
          Please share your observation/estimate of drought damage, health and
          mental suffering, and/or local stories.
        </p>
        <p align="left">Please bring up your opinions on drought mitigation.</p> */}
        <p align="left">
          The flooding has led to severe financial losses for farms and small
          businesses, heightened anxiety or depression due to economic damage, conflicts over limited resources, increased workloads, reduced recreational opportunities, threats from environmental changes such as soil erosion and property damage, health incidents (e.g., waterborne illnesses), and isolation from community networks.{" "}
          <b>Have you or people you know experienced any of these impacts?</b>
        </p>
        <p align="left">
          Please share your observations or estimates regarding flood-related damage, health and mental well-being challenges, and/or local stories.
        </p>
        <p align="left">We would also appreciate your thoughts on flood mitigation strategies.</p>

        <p align="left">
          You can also review the inputs from others by clicking Display in
          top-right corner.
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1, marginTop: 15 }}>
            <TextField
              style={{ width: 350 }}
              id="outlined-basic"
              label="Flooding Situation"
              variant="outlined"
              placeholder="Please describe your local flooding situation, you can also add the URL of photos or articles here."
              multiline
              value={this.state.content}
              onChange={this.handleChange}
              minRows={5}
              maxRows={10}
            />
          </div>
          <div style={{ marginTop: 40 }}>
            <TextField
              // style={{ width: 250 }}
              id="outlined-basic"
              label="Your Name"
              variant="outlined"
              placeholder="This is optional"
              value={this.state.username}
              onChange={this.handleChangeName}
            />
          </div>
          <div style={{ marginTop: 40 }}>
            <TextField
              style={{ width: 92 }}
              id="outlined-basic"
              label="Zip Code"
              variant="outlined"
              placeholder="61820"
              value={this.state.zip}
              onChange={this.handleChangeZip}
            />
          </div>
          <div style={{ marginTop: 40 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.pushDataToFirebase}
            >
              Submit
            </Button>
          </div>

          <div style={{ position: 'fixed', right: '2%', bottom: '2%' }}>
          <IconButton onClick={this.toggleChat}>
            {isChatOpen ? <CloseIcon /> : <ChatBubbleOutlineIcon />}
          </IconButton>
        </div>
      <div style={chatStyle}>
<DeepChat
        style={customStyles}
        textInput={textInputConfig}
        messageStyles={messageStylesConfig}
        avatars={avatarsConfig}
        // speechToText={speechToTextConfig}
        submitButtonStyles={submitButtonStylesConfig}
        initialMessages={initialMessages}
        stream={true}
        directConnection={directConnection}

        />
</div>
        </div>
        <br /><br /><br />
        <h4 align="left">Inputs from other users</h4>
        
        <Display />
      </div>
    );
  }
}

export default UserInput;