import "./App.css";
import { Switch, Route, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import "bootstrap/dist/css/bootstrap.css";
import Home from "./components/Home";
import WebLink from "./components/WebLink";
import UserInput from "./components/UserInput";
// import Display from "./components/Display";
import SocialMedia from "./components/SocialMedia";
// import Insights from "./components/Insights";
import HighLights from "./components/HightLights";
import DrawerComponent from "./Drawer";
import React, { useEffect } from "react";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const customTheme = createTheme({
  typography: {
    fontFamily: ["Encode Sans SC"].join(","),
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: theme.spacing(0.5),
  },
  title: {
    flexGrow: 1,
  },
  demo2: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  padding: {
    fontSize: 25,
  },
}));

const StyledTabs = withStyles({
  flexGrow: 1,
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 70,
      width: "100%",
      backgroundColor: "grey",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#fff",
    font: "Encode Sans SC",
    // fontWeight: theme.typography.fontWeightRegular,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(18),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);


function App() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // console.log( newValue );
  };
  // useEffect(() => {
  //    document.title = "DIP-Drought";
  //  }, []);

  useEffect(() => {
    //console.log('location', window.location.pathname)
    switch (window.location.pathname) {
      case "/home":
        setValue(0);
        break;
      case "/articles":
        setValue(1);
        break;
      case "/socialmedia":
        setValue(2);
        break;
      case "/highlight":
        setValue(4);
        break;
      // case "/insight":
      //   setValue(3);
      //   break;
      // case "/display":
      //   setValue(5);
      //   break;
      case "/userinput":
        setValue(6);
        break;
    }
  }, [window.location]);
  
  return (
    <div className="App">
      <div className={classes.root}>
        <AppBar position="static" style={{ background: "#13294b" }}>
          <Toolbar disableGutters>
            {isMobile ? (
              <DrawerComponent />
            ) : (
              <div className={classes.demo2}>
                {/* <div style={ { flexGrow: 1, marginRight: 100 } }> */}
                <div style={{ flexGrow: 1, marginRight: 1 }}>
                  <p
                    style={{ fontFamily: "Encode Sans SC", margin: "0" }}
                    className={classes.padding}
                  >
                    <IconButton
                      edge="start"
                      className={classes.menuButton}
                      onClick={(event, newValue) => {
                        setValue(0);
                      }}
                      color="inherit"
                      aria-label="menu"
                      to="/"
                      component={Link}
                    >
                      <img src="/uiuc.png" alt="Logo" height="80" />
                    </IconButton>
                  </p>
                </div>
                <ThemeProvider theme={customTheme}>
                  <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs navbar"
                    centered
                  >
                    <StyledTab label="Home" to="/home" component={Link} />
                    <StyledTab
                      label="Articles"
                      to="/articles"
                      component={Link}
                    />
                    <StyledTab
                      label="SocialMedia"
                      to="/socialmedia"
                      component={Link}
                    />
                    <StyledTab
                      label="HighLights"
                      to="/highlight"
                      component={Link}
                    />
                    {/* <StyledTab
                      label="Insights"
                      to="/insight"
                      component={Link}
                    /> */}
                    <StyledTab 
                      label="Share" 
                      to="/userinput" 
                      component={Link} 
                    />

                    {/* <StyledTab
                      label="UserInputs"
                      to="/display"
                      component={Link}
                    /> */}
                  </StyledTabs>
                </ThemeProvider>
              </div>
            )}
            {/* <Button color="inherit" onClick={(event, newValue) => { setValue("login") }} component={Link} to="/user">Login</Button> */}
          </Toolbar>
        </AppBar>
      </div>
      <div>
        <Switch>
          <Route
            exact
            path={["/", "/home"]}
            render={(props) => <Home {...props} setValue={setValue} />}
          />
          <Route
            path="/articles"
            render={(props) => <WebLink {...props} setValue={setValue} />}
          />
          <Route
            path="/userinput"
            render={(props) => <UserInput {...props} setValue={setValue} />}
          />
          <Route
            path="/socialmedia"
            render={(props) => <SocialMedia {...props} setValue={setValue} />}
          />
          {/* <Route
            path="/insight"
            render={(props) => <Insights {...props} setValue={setValue} />}
          /> */}
          <Route
            path="/highlight"
            render={(props) => <HighLights {...props} setValue={setValue} />}
          />
        </Switch>
      </div>
      <a href="https://clustrmaps.com/site/1bo9k" title="Visit tracker">
        <img src="//www.clustrmaps.com/map_v2.png?d=XfYwJ6V685xHxR5-6Uqgv9F1tEhwV0xO4dQFNZOs9cM&cl=ffffff" />
      </a>
    </div>
  );
}

export default App;
