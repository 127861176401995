// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, push } from "firebase/database";
// import firebase from 'firebase'
import "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDYwDDNCJvfKo5KFoAmCcD5d00GqUuo-bE",
//   authDomain: "droughtproject-25e61.firebaseapp.com",
//   projectId: "droughtproject-25e61",
//   storageBucket: "droughtproject-25e61.appspot.com",
//   messagingSenderId: "845930865448",
//   appId: "1:845930865448:web:0f72f90e359ccb5d7ba61d",
//   measurementId: "G-136V9J4LTL",
// };

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBPZQzDz6OX4xd-HwhKN4RovLLHGEpYwRI",
  authDomain: "floodweb-56c76.firebaseapp.com",
  projectId: "floodweb-56c76",
  storageBucket: "floodweb-56c76.appspot.com",
  messagingSenderId: "1048964180422",
  appId: "1:1048964180422:web:f4eff0f59e77c7d5c0eac7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getDatabase();
const storage = getStorage(app);

export { db, storage };

export const writeUserData = async (content, zip, name = "Anonymous") => {
  // const db = getDatabase();
  var newPostKey = push(ref(db, "users")).key;
  try {
    await set(ref(db, "users/" + newPostKey), {
      username: name,
      content: content,
      zip: zip
    });
  } catch (error) {
    console.error("Error adding data from firebase", error);
  }
};

// 创建一个 'web' 节点，值为空对象 {}
export const createWebNode = async () => {
  try {
    await set(ref(db, 'web'), {});  // 异步写入 web 节点
    console.log('Web node created successfully');
  } catch (error) {
    console.error('Error creating web node:', error);
  }
};

// 调用创建 web 节点的函数
createWebNode();